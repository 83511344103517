@media print {
  body { transform: scale(.8); }
  table { page-break-inside: avoid; }
  #order-details-actions { display: none; }
  #header { display: none; }
  #main-nav { display: none; }
  @page {
    margin: 5mm 5mm 5mm 5mm;
  }
  #order-details-container {
    margin: 10px;
    color: black !important;
  }
}
