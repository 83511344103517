html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Ringside Condensed A, Ringside Condensed B;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #0F1321;
}

button:disabled {
  opacity: 0.5;
}

.col {
  padding: 1rem;
  background-color: #33b5e5;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  }

a.ot-sdk-show-settings {
  color: #FFFFFF;
  cursor: pointer;
  height: 20px;
  font-size: 14px;
  text-align: left;
  font-family: Ringside Condensed A, Ringside Condensed B;
  font-weight: bold;
  padding-left: 10px;
  line-height: 3;
  text-decoration: underline;
  text-decoration-color: #0078D4;
  text-underline-position: under;
}

img.privacy-icon {
  width: 30px;
  height: 15px;
  margin-left: 5px;
}

privacy-settings {
  display: none;
}

.errorInput {
  background-clip:padding-box;
  border-color: #dc3545 !important;
}

.errorInput:focus{
  background-clip:padding-box;
  transition: border-color .15s ease-in-out; 
  box-shadow: .15s ease-in-out !important;
  background-color: #fff !important;
  box-shadow: 0 0 0 .2rem #f2d6db !important;
  border: 1px solid #dc3545  !important;
  outline: none;
}

.errorInput:not(:disabled):focus {
  background-clip:padding-box;
  box-shadow: 0 0 0 .2rem #f2d6db !important;
}

.successInput {
  background-clip:padding-box;
  border-color: #28a745 !important;
}

.successInput:focus{
  background-clip:padding-box;
  transition: border-color .15s ease-in-out; 
  box-shadow: .15s ease-in-out !important;
  background-color: #fff !important;
  box-shadow: 0 0 0 .2rem #c4e7d3!important;
  border: 1px solid #28a745  !important;
  outline: none;
}

.successInput:not(:disabled):focus {
  background-clip:padding-box;
  box-shadow: 0 0 0 .2rem #c4e7d3 !important;
}